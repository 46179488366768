import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/annual-report/post-layout/post-layout.tsx";
import Chart from './chart.tsx';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <blockquote>
      <p parentName="blockquote">{`Student numbers are very important to international schools as they make up almost all of their annual revenue, which in turn, allows PSI to run additional  programmes and more subjects for students.`}</p>
    </blockquote>
    <p>{`PSI has grown from an initial enrolment of 47 students to a school of just over 500 students since it opened in 1996. Enrolment had been quite stable and was growing every year, peaking at 506 students this school year.`}</p>
    <p>{`Although there is not a goal to ‘grow the school,’ strategies are in place to maintain a ‘healthy enrolment’ aimed at 500 to 550  students.`}</p>
    <Chart mdxType="Chart" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      